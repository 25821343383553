import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { getPartyName } from "../helpers/party";
import { toTitleCase } from "../helpers";

function CandidatePage({ data }) {
  const candidate = data.ngelectionapi.candidate;
  const candidateNews = candidate.candidateNews
    ?.sort((a, b) => {
      return b.date - a.date;
    })
    .splice(0, 15);

  return (
    <Layout>
      <SEO
        keywords={[toTitleCase(candidate.name), "candidate", "elections"]}
        description={candidate.summary}
        title={`${candidate.name}${
          candidate.election?.name ?? ` | ${candidate.election?.name}`
        }`}
        image={candidate.image}
      />

      <h2 className="text-2xl font-bold border-b-4 border-black py-3 mb-18">
        {toTitleCase(candidate.name)}
      </h2>

      <div className="grid grid-cols-6 gap-4 my-3 mb-10">
        <div className="sm:col-span-1 col-span-full">
          <img
            className="w-full object-cover object-center rounded-md border border-gray-200 shadow-lg"
            src={candidate.image || "/images/person-icon.png"}
            loading="lazy"
            alt={toTitleCase(candidate.name) + "'s photo"}
          />
        </div>

        <div className="sm:col-span-5 col-span-full">
          <p className="font-bold mb-1">{candidate.summary}</p>
          {candidate.state && (
            <p>
              <span className="font-bold">State: </span>
              {candidate.state}
            </p>
          )}
          {candidate.lga && (
            <p>
              <span className="font-bold">Constituency: </span>
              {candidate.lga}
            </p>
          )}
          {candidate.party && (
            <p>
              <span className="font-bold">Party: </span>
              {getPartyName(candidate.party)}
            </p>
          )}
        </div>
      </div>

      <h3 className="text-xl font-bold border-b-4 border-black py-2 mb-2 mt-6">
        Bio
      </h3>
      <div
        className="candidate-bio"
        dangerouslySetInnerHTML={{ __html: candidate.bio }}
      />

      <h3 className="text-xl font-bold border-b-4 border-black py-2 mb-2 mt-6">
        Policy Positions
      </h3>
      <section className="candidate-policies">
        {candidate.candidatePolicies.map((cp, i) => {
          return (
            <React.Fragment key={i}>
              <h4 className="text-xl font-bold mb-3 mt-3">
                &#x2192; {cp.policy.name}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: cp.description }} />
            </React.Fragment>
          );
        })}
      </section>

      <h3 className="text-xl font-bold border-b-4 border-black py-2 mb-2 mt-6">
        In The News
      </h3>
      <section className="candidate-news">
        {candidateNews.map((cn, i) => {
          return (
            <article key={i} className="mb-2">
              <h5>
                <a
                  href={cn.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-green-600 hover:text-green-700"
                >
                  {cn.title}{" "}
                  <sup>
                    <small>🔗</small>
                  </sup>
                </a>
              </h5>
              <h6 className="text-xs text-opacity-60">
                {new Date(cn.date).toDateString()} |{" "}
                <span>{cn?.source?.name}</span>
              </h6>
            </article>
          );
        })}
      </section>
    </Layout>
  );
}

CandidatePage.propTypes = {
  data: PropTypes.object,
};

export default CandidatePage;

export const query = graphql`
  query ($slug: String!) {
    ngelectionapi {
      candidate(slug: $slug) {
        image
        name
        summary
        bio
        state
        lga
        party
        candidatePolicies {
          policy {
            name
            slug
          }
          description
          link
          source {
            name
            tier
          }
        }
        candidateNews {
          title
          link
          date
          source {
            name
          }
        }
        election {
          name
        }
      }
    }
  }
`;
